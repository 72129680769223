
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import printJs from 'print-js';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { injectSearch } from '@/core/plugins/search-service';
  import SearchField from '@/components/common/SearchField.vue';
  import moment from 'moment';
  import { loadImage } from '../../../utils/loadImage';
  import { format12 } from '@/utils/dateFormat';

  export default defineComponent({
    name: 'horses-report',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const loadingPage = ref(true);
      const searchService = injectSearch();
      const reportData = ref();
      const selectedCustomer = ref<number>(0);
      const selectedTrainer = ref<number>(0);
      const selectedHorse = ref<number>(0);
      const selectedArena = ref<number>(0);
      const selectedAttendance = ref<number>(0);
      const selecteDuration = ref<number>(0);
      const selecteType = ref<number>(0);
      const selectedHorseType = ref<number>(0);
      const selectedDay = ref<number>(0);
      const selectedGroomer = ref<number>(0);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const startDate = ref(moment().startOf('day')._d);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const endDate = ref(moment().startOf('day')._d);
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('HorsesReport'), [
          translate('reports'),
        ]);
        store.dispatch(Actions.GET_ALL_CUSTOMERS);
        store.dispatch(Actions.GET_ALL_TRAINERS, {});
        store.dispatch(Actions.GET_ALL_ARENAS);
        store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS);
        const getSaveData = searchService.getSearchValue('HorsesReport');
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(searchService.getSearchValue('HorsesReport'))
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.startDate) startDate.value = dataSaved.startDate;
          if (dataSaved.endDate) endDate.value = dataSaved.endDate;
          if (dataSaved.horseId) selectedHorse.value = dataSaved.horseId;
          if (dataSaved.groomerId) selectedGroomer.value = dataSaved.groomerId;
          if (dataSaved.arenaId) selectedArena.value = dataSaved.arenaId;
          if (dataSaved.horseTypeId)
            selectedHorseType.value = dataSaved.horseTypeId;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });
      const clearSearch = () => {
        searchService.removeSearchValue('HorsesReport');
        selectedCustomer.value = 0;
        selectedHorse.value = 0;
        selectedGroomer.value = 0;
        selectedArena.value = 0;
        selectedHorseType.value = 0;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        startDate.value = moment().startOf('day')._d;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        endDate.value = moment().startOf('day')._d;
      };

      const print = async (values) => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
        values.startDate = String(values.startDate);
        values.endDate = String(values.endDate);

        searchService.setSearchValue('HorsesReport', values);

        const user = getUser();
        const userName = user ? user['name'] : '';

        await store.dispatch(Actions.GET_HORSE_BY_GROUP, values);

        reportData.value = store.getters.getHorseByGrp;

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'portrait',
        });
        const fontSize = 10;

        doc.setFontSize(fontSize);
        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;

        const dataLength = reportData.value.data.length;
        if (dataLength === 0) {
          doc.text('No Result Found', 120, 70);
        }

        let pageCount = 0;
        const dataKeys = Object.keys(reportData.value.data);

        dataKeys.forEach(function (element, index) {
          if (index > 0) {
            doc.addPage();
          }

          const dayOfWeek = moment(element).format('dddd');

          doc.text(`${dayOfWeek} ${element}`, 20, 55);
          const elementKeys = Object.keys(reportData.value.data[element]);
          const resTabelBody: any[] = [];
          let horseName = '';
          let trainerName = '';
          let arenaName = '';
          let groomerName = '';
          let id = '';
          elementKeys.forEach((elementb) => {
            reportData.value.data[element][elementb].map((eObject, i) => {
              horseName += `${eObject.horseName}` + '\n';
              trainerName += `${eObject.trainerName}` + '\n';
              arenaName += `${eObject.arenaName}` + '\n';
              groomerName +=
                `${eObject.groomerName ? eObject.groomerName : ''}` + '\n';
              id += `${i + 1}` + '\n';
            });
            resTabelBody.push([
              format12(elementb),
              id,
              horseName,
              trainerName,
              arenaName,
              groomerName != 'undefined' ? groomerName : '',
            ]);
            horseName = ``;
            id = '';
            trainerName = '';
            arenaName = '';
            groomerName = '';
          });

          autoTable(doc, {
            head: [
              ['Time Slot', 'Sr.', 'Horse', 'Trainer', 'Arena', 'Groomer'],
            ],
            body: resTabelBody,
            startY: 40,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            margin: { horizontal: 'auto', top: 40 },
            horizontalPageBreak: false,
            theme: 'grid',
            headStyles: {
              fillColor: [0, 72, 115],
              fontStyle: 'bold',
              halign: 'left',
            },
            bodyStyles: {
              fontStyle: 'bold',
              halign: 'left',
            },
            styles: {
              fontSize: 10,
            },
            didDrawPage: function () {
              doc.setFontSize(10);
              pageCount++;
              const pageCountWidth = doc.getTextWidth(String(pageCount));
              console.log(pageCountWidth);

              doc.text(
                'Page ' + pageCount,
                textX - pageCountWidth,
                doc.internal.pageSize.height - 9
              );
              doc.setFontSize(fontSize);
            },
          });
        });
        const logoImage = await loadImage('/media/logos/empty-krc.png');
        Array.from({ length: pageCount }, (x, i) => {
          doc.setFontSize(9);
          doc.text(
            'Start Date: ' + moment(values.startDate).format('LL'),
            60,
            35
          );
          doc.text('End Date: ' + moment(values.endDate).format('LL'), 105, 35);
          doc.setFontSize(14);
          doc.setFont('helvetica', 'bold');
          doc.text('Kuwait Riding Center', textX, 15);
          doc.setFontSize(12);
          doc.text('Daily Schedule Report For Horses', textX - 5, 22);
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(10);

          doc.text('Print DateTime: ' + moment(new Date()).format('LLL'), 5, 8);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          doc.addImage(logoImage, 'png', 5, 3, 35, 35);
          doc.setPage(i + 1);
          doc.text(userName, 5, doc.internal.pageSize.height - 10);

          doc.text(
            '   of ' + doc.getNumberOfPages(),
            textX + 10,
            doc.internal.pageSize.height - 9
          );
          doc.setFontSize(fontSize);
        });

        // doc.save('table.pdf');
        // doc.autoPrint();
        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');

        window.open(blobUrl, '_blank');

        // printJs({ printable: blobUrl, type: 'pdf' ,target: '_blank' });
      };

      // const loadImage = async (url) => {
      //   return new Promise((resolve, reject) => {
      //     var xhr = new XMLHttpRequest();
      //     xhr.onload = function () {
      //       var reader = new FileReader();
      //       reader.onloadend = async function () {
      //         resolve(reader.result);
      //       };
      //       reader.readAsDataURL(xhr.response);
      //     };
      //     xhr.onerror = function () {
      //       reject(new Error('Failed to load image'));
      //     };
      //     xhr.open('GET', url);
      //     xhr.responseType = 'blob';
      //     xhr.send();
      //   });
      // };

      const validationSchema = Yup.object().shape({});
      // const onSubmitCreate = async (values) => {
      //   debugger;
      //   if (submitButton.value) {
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = true;
      //     submitButton.value.setAttribute('data-kt-indicator', 'on');
      //   }
      //   values.duration = Number(values.duration);
      //   values.numberOfRider = Number(values.numberOfRider);

      //   const { data } = await store.dispatch(
      //     Actions.GET_CUSTOMER_ATTENDANCE,
      //     values
      //   );
      //   const [errorName] = Object.keys(store.getters.getErrors);
      //   const error = store.getters.getErrors[errorName];

      //   if (error) {
      //     Swal.fire({
      //       text: translate(error[0]),
      //       icon: 'error',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('tryAgainExcl'),
      //       customClass: {
      //         confirmButton: 'btn fw-bold btn-light-danger',
      //       },
      //     });
      //     //Deactivate indicator
      //     submitButton.value?.removeAttribute('data-kt-indicator');
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = false;
      //   } else {
      //     Swal.fire({
      //       text: translate('SUCCESSFULLY_CREATED_CLASS_CATEGORY'),
      //       icon: 'success',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('ok'),
      //       customClass: {
      //         confirmButton: 'btn btn-light-primary',
      //       },
      //     }).then(() => {
      //       router.push({ name: 'class-category-listing' });
      //     });
      //   }
      // };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.DAYS,
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.DURATION,
        LookupNameIds.STATUS,
        LookupNameIds.ATTENDEND,
        LookupNameIds.HORSE_TYPE,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.ATTENDEND;
      });

      // const categoryList = await store.dispatch(
      //   Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      // );
      const classType = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
      });

      const dayList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DAYS;
      });

      const durationList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DURATION;
      });
      const horseTypeList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_TYPE;
      });

      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        reportData,
        customers: computed(() => store.getters.allCustomersList),
        trainers: computed(() => store.getters.allTrainersList),
        arenas: computed(() => store.getters.allArenasList),
        horses: computed(() => store.getters.allHorsesList),
        groomers: computed(() => store.getters.allGroomerList),
        statusList,
        classType,
        // categoryList,
        dayList,
        durationList,
        selectedCustomer,
        selectedTrainer,
        selectedArena,
        selectedHorse,
        selecteDuration,
        selectedAttendance,
        selecteType,
        selectedDay,
        selectedGroomer,
        selectedHorseType,
        horseTypeList,
        startDate,
        endDate,
        loadingPage,
        clearSearch,
      };
    },
  });
